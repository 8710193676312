import * as React from "react"
import styled from "styled-components"
import { primaryLight } from "../../constants/colors"
import { tablet } from "../../../constant/screenSizes"

const Container = styled.div`
  margin: 20px auto;
  max-width: 1000px;
`

const Title = styled.h2`
  text-align: center;
  margin-bottom: 40px;
`

const StepsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 40px);
  margin-left: -20px;

  @media (max-width: ${tablet}px) {
    padding: 0 15px;
  }
`

const Step = styled.div`
  width: 50%;
  padding: 20px;

  @media (max-width: ${tablet}px) {
    width: 100%;
  }
`
const StepTitle = styled.h4`
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.1em;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-transform: none;

  span {
    background-color: ${primaryLight};
    border-radius: 25px;
    color: white;
    padding: 7px;
    height: 30px;
    width: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 1px;
    margin-right: 10px;
    font-size: 0.6em;
  }
`
const Description = styled.p`
  font-size: 0.9em;
`

interface StepType {
  title: string
  description: string
}

interface PropsType {
  title?: string
  steps: StepType[]
}

const Steps = ({ title, steps }: PropsType) => {
  return (
    <Container>
      {title && <Title>{title}</Title>}
      <StepsContainer>
        {steps.map(({ title, description }, index) => (
          <Step key={title}>
            <StepTitle>
              <span>0{index + 1}</span>
              {title}
            </StepTitle>
            <Description>{description}</Description>
          </Step>
        ))}
      </StepsContainer>
    </Container>
  )
}

export default Steps
