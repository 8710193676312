import * as React from "react"
import { useRef } from "react"
import Layout from "../../../components/Layout"
import Seo from "../../../components/Seo"
import Steps from "../../components/landing-page/Steps"
import * as colors from "../../constants/colors"
import CorporateContact from "../../components/landing-page/CorporateContact"
import LandingPageStrip from "../../components/landing-page/LandingPageStrip"
import styled from "styled-components"
import { tablet } from "../../../constant/screenSizes"
import AlgoliaProductCarousel from "../../components/carousel/AlgoliaProductCarousel"
import LPBrochureCTABlock from "../../components/landing-page/new/components/cta/LPBrochureCTABlock"

const Header = styled.div`
  max-width: 1000px;
  margin: auto;
  text-align: center;

  @media (max-width: ${tablet}px) {
    padding: 0 15px;
  }
`

const SectionPanels = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 40px;
`

const GIFT_STEPS = [
  {
    title: "Choose Your Contents",
    description:
      "Select our ready-to-go gift box or create your own version designed with your team in mind and choose from over 1,000 products.",
  },
  {
    title: "Design",
    description:
      "Select your box and design from our range of different packaging options. We can offer branded gift boxes with your logo or a message on too if you’d like.",
  },
  {
    title: "PERSONALISE",
    description: "Add a gift note or personalised message to each gift box.",
  },
  {
    title: "DELIVERY",
    description:
      "Work with us to set a delivery date. We can send boxes to anywhere in the world. We can deliver orders from 1 to 25,0000 gift boxes. All you have to do is place them on their desks.",
  },
]

const SUPPLIES_STEPS = [
  {
    title: "CHOOSE YOUR CONTENTS",
    description:
      "We have over 1,000 products that can help to make your office more sustainable and socially impactful. From the soap in the bathroom to the tea and coffee in the kitchen. Put the causes your company cares about into the heart of your workplace.",
  },
  {
    title: "FREQUENCY",
    description:
      "Let us know how often you want to receive your products – from weekly top-ups for bathroom essentials to cleaning refills every fortnight.",
  },
  {
    title: "ONE-OFFS",
    description:
      "We can provide something a bit special for those moments when you need them. Perhaps you’d like some ‘evergreen’ paper flowers for your conference room to wow clients. Or, an extra order of snacks for a team building event. Top up a regular order or simply place a unique order when you need it.",
  },
  {
    title: "DELIVERY",
    description:
      "Work with us to set a delivery date and provide address details. We can send your products to one HQ or multiple locations.",
  },
  {
    title: "IMPACT REPORTING & STORYTELLING",
    description:
      "Automated reporting shows the quantified impact of each order – lives impacted, materials saved from landfill and profits donated to Charity - while our marketing team helps you create storytelling touchpoints to engage your employees around the impact you are making.",
  },
]

interface Props {}

const ReturnToTheOfficePage = ({}: Props) => {
  const giftsRef = useRef(null)
  const suppliesRef = useRef(null)
  const suppliesContactRef = useRef(null)
  return (
    <Layout fullWidth>
      <Seo
        title={"Return to the office"}
        description={"Social Supermarket return to the office gift boxes and supplies"}
      />
      <LandingPageStrip>
        <Header>
          <h1>Office Supplies</h1>
          <p>
            Every procurement decision has a social or environmental impact, whether intended or
            not. Fortunately, we see an increasing trend in organisations using their buying power
            to generate social value. Whatever the motivation - employees and clients expecting
            more, market differentiation or a stronger focus on compliance - Social Supermarket is
            working with progressive organisations to create positive ripples of change for people
            and planet. We invite you to join this movement today.
          </p>
        </Header>
      </LandingPageStrip>
      <LandingPageStrip color={colors.grey}>
        <Steps title={"HOW TO ORDER SOCIAL IMPACT SUPPLIES"} steps={SUPPLIES_STEPS} />
      </LandingPageStrip>

      <LPBrochureCTABlock
        subheading={"RIPPLES OF CHANGE"}
        title={"Social Impact Audit"}
        description={
          "Social Supermarket introduced the Social Impact Audit to help clients understand the positive social and environmental impact a\n" +
          "few small swaps in the workplace can make."
        }
        buttonLabel={"Find Out More"}
        buttonTo={
          "https://data.socialsupermarket.org/wp-content/uploads/2018/10/SSM-Social-Impact-Audit-1.pdf"
        }
        imageSrc={
          "https://data.socialsupermarket.org/wp-content/uploads/2018/10/Screenshot-2023-11-09-at-18.23.46.png"
        }
        leadCapture
        leadCaptureTitle={"Download the impact audit overview"}
        leadCaptureDescription={
          "Please provide your details to download the impact audit overview."
        }
        leadCaptureEventName={"DownloadImpactAuditOverview"}
        leadCaptureAnalyticsName={"download_impact_audit_overview"}
      />

      <LandingPageStrip color={colors.grey}>
        <AlgoliaProductCarousel
          collectionName={"Bathroom Essentials That Do Good"}
          title="Bathroom Essentials That Do Good"
          large
          showImpact
          hidePrice
          noLink
        />
      </LandingPageStrip>
      <LandingPageStrip>
        <AlgoliaProductCarousel
          collectionName={"Stock Kitchen & Pantry"}
          title="Stock the Kitchen & Pantry"
          large
          showImpact
          hidePrice
          noLink
        />
      </LandingPageStrip>
      <LandingPageStrip color={colors.grey}>
        <AlgoliaProductCarousel
          collectionName={"Green Cleaning Supplies"}
          title="Green Cleaning Supplies"
          large
          showImpact
          hidePrice
          noLink
        />
      </LandingPageStrip>
      <div ref={suppliesContactRef}>
        <LandingPageStrip color={colors.primaryLight}>
          <CorporateContact invert>
            To discuss your requirements further, please get in touch using the form below:
          </CorporateContact>
        </LandingPageStrip>
      </div>
    </Layout>
  )
}

export default ReturnToTheOfficePage
